import {getFormattedFullDate, getFormattedFullLocation} from '@wix/wix-events-commons-statics/dist/selectors/event'
import * as React from 'react'
import {MonthlyCalendarEventDetailsProps} from '.'
import {EventImage} from '../../../../../event-image'
import {Members} from '../../../../../members'
import {Ribbon} from '../../../../../ribbon'
import {RsvpButton} from '../../../../../rsvp-button'
import {SocialBar} from '../../../../../social-bar'
import {PopupHeader} from '../header'
import * as s from './monthly-calendar-event-details.scss'

export class MonthlyCalendarEventDetails extends React.PureComponent<MonthlyCalendarEventDetailsProps> {
  onBack = () => this.props.closeMonthlyCalendarEvent()

  render() {
    const {
      dateText,
      event,
      dayEventCount,
      showImage,
      showFullDate,
      showLocation,
      showDescription,
      showShare,
      showMembers,
      fullLocale,
      shareFontSize,
      imagePosition,
      imageOpacity,
      closeMonthlyCalendarPopup,
      t,
      showRibbon,
    } = this.props

    return (
      <div className={s.container} data-hook={`calendar-event-details`}>
        <PopupHeader
          eventDetails
          text={dateText}
          onClose={closeMonthlyCalendarPopup}
          onBack={dayEventCount > 1 ? this.onBack : undefined}
          t={t}
        />
        <div className={s.eventDetailsContent}>
          {showImage ? (
            <div className={s.image}>
              <div className={s.content} data-hook={`image`}>
                <EventImage
                  event={event}
                  imagePosition={imagePosition}
                  imageOpacity={imageOpacity}
                  width={500}
                  height={500}
                />
              </div>
            </div>
          ) : null}
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <div className={s.title} data-hook="title">
            {event.title}
          </div>
          {showFullDate ? (
            <div className={s.fullDate} data-hook="date">
              {getFormattedFullDate(event, fullLocale)}
            </div>
          ) : null}
          {showLocation ? (
            <div className={s.location} data-hook="location">
              {getFormattedFullLocation(event)}
            </div>
          ) : null}
          {showMembers ? (
            <div className={s.members} data-hook="members">
              <Members event={event} calendarLayout={true} />
            </div>
          ) : null}
          {showDescription && event.description ? (
            <div className={s.description} data-hook="description">
              {event.description}
            </div>
          ) : null}
          {showShare ? (
            <div className={s.share} data-hook="share">
              <div className={s.shareText}>{t('shareEventText')}</div>
              <SocialBar t={t} spacing={7} event={event} iconFontSize={shareFontSize} row hideTitle />
            </div>
          ) : null}
        </div>
        <div className={s.button}>
          <RsvpButton event={event} dataHook="rsvp-button" minWidth={88} additionalStyle={{minHeight: 32}} />
        </div>
      </div>
    )
  }
}
