import {getFullMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../commons/selectors/environment'
import {getReferenceDate} from '../../../selectors/calendar-layout'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps} from './interfaces'
import {MonthlyCalendar as MonthlyCalendarPresentation} from './monthly-calendar'

const mapRuntime = ({actions, state}: AppProps): MonthlyCalendarRuntimeProps => ({
  referenceDate: getReferenceDate(state),
  fullMonthText: getFullMonthText(getReferenceDate(state), getFullLocale(state)),
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
  resetCalendar: actions.resetCalendar,
})

export const MonthlyCalendar = connect<MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps>(mapRuntime)(
  withNamespaces()(MonthlyCalendarPresentation),
)
export * from './interfaces'
