import {TranslationFunction} from 'i18next'

export interface PopupHeaderOwnProps {
  text: string
  eventList?: boolean
  eventDetails?: boolean
  onClose: () => void
  onBack?: () => void
  t: TranslationFunction
}

export interface PopupHeaderProps extends PopupHeaderOwnProps {}

export {PopupHeader} from './popup-header'
