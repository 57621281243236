import {getFormattedFullDate, getFormattedFullLocation} from '@wix/wix-events-commons-statics/dist/selectors/event'
import * as classNames from 'classnames'
import * as React from 'react'
import {FullDateLocationProps} from '.'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {DH} from '../../utils/data-hooks'
import * as s from './full-date-location.scss'

export class FullDateLocation extends React.Component<FullDateLocationProps> {
  getContainerClasses = () => classNames({[s.noDate]: !this.props.showDate, [s.noLocation]: !this.props.showLocation})

  render() {
    const {event, fullLocale} = this.props

    return (
      <div className={this.getContainerClasses()} data-hook={DH.fullDateLocation}>
        <div className={s.date} data-hook="date">
          <BrowserOnly>{getFormattedFullDate(event, fullLocale)}</BrowserOnly>
        </div>
        <div className={s.location} data-hook="location">
          {getFormattedFullLocation(event)}
        </div>
      </div>
    )
  }
}
