export {Title} from './title'

export interface TitleProps {
  active: boolean
  event: wix.events.Event
  opened: boolean
  listLayout: boolean
  showArrow: boolean
  withOverflow?: boolean
  mobile?: boolean
}
